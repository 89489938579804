import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

// LIBS
import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// COMPONENTS
import { HeaderTitle } from '@components/shared'
import { LoadingButton } from '@mui/lab'
import {
  Chip,
  TextField,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material'
import { DataGrid, GridColumns } from '@mui/x-data-grid'

// ICONS
import { DeleteForever, Person, PersonSearch } from '@mui/icons-material'

// SERVICES
import {
  deleteInventoryItemFromPlayer,
  getInventoryItemsBySteamId64,
} from '@services/inventory/InventoryService'

// ENV
import { HOST_URL } from '@config/environment'

// TYPES
import {
  InventoryItem,
  InventoryRequestParams,
  InventoryRequestProps,
} from '@shared/types/InventoryTypes'

// STYLES
import * as Styled from './PlayerInventory.styles'
import { pink } from '@mui/material/colors'

const PlayerInventory = (): JSX.Element => {
  const { steamId } = useParams()

  const [inventoryItems, setInventoryItems] = useState<InventoryItem[]>()

  const [filteredItems, setFilteredItems] = useState<InventoryItem[]>()

  const [currentCategory, setCurrentCategory] = useState<unknown>('')

  const [steamId64, setSteamId64] = useState(steamId ?? '')

  const [loading, setLoading] = useState(false)

  const [rowCount, setRowCount] = useState(0)

  const [categories, setCategories] = useState<string[]>([])

  const _getInventoryItemsBySteamId64 = async (
    params: InventoryRequestParams
  ): Promise<void> => {
    try {
      setLoading(true)
      const inventoryData: InventoryRequestProps =
        await getInventoryItemsBySteamId64(steamId64, params)
      setInventoryItems(inventoryData.results)
      setRowCount(inventoryData.pagination.totalItems)

      const inventoryCategories: string[] = []
      inventoryData.results.map((inventoryItem) => {
        !inventoryCategories.includes(inventoryItem.category) &&
          inventoryCategories.push(inventoryItem.category)
      })

      setCategories(inventoryCategories)
    } finally {
      setLoading(false)
    }
  }

  const _handleInputChange = (value: string): void => {
    setSteamId64(value)
  }

  const _handleSearch = (): void => {
    const params: InventoryRequestParams = {
      limit: 500,
      page: 1,
    }

    if (steamId64 === '') return

    setCurrentCategory('')
    setFilteredItems([])
    _getInventoryItemsBySteamId64(params)
  }

  const _handleItemDeletion = async (
    id: number,
    slug: string
  ): Promise<void> => {
    Alert.remove(
      `Você está prestes a remover o produto ${slug} do inventário do player e essa ação não pode ser desfeita. Deseja prosseguir?`
    )
      .then(async (result) => {
        if (result.isConfirmed) {
          await deleteInventoryItemFromPlayer(id)

          Alert.success('Produto removido com sucesso.', _handleSearch())
        }
      })
      .catch((e) => {
        Alert.error('Não foi possível remover o item')
      })
  }

  const _handleProductFiltering = (category: unknown): void => {
    setCurrentCategory(category)
    const filtered = inventoryItems.filter((item) => item.category == category)
    setFilteredItems(filtered)
    setRowCount(filtered.length)
  }

  const _renderProductLink = (slug: string): JSX.Element => {
    return (
      <Styled.Link
        href={`${HOST_URL}/loja/produto/${slug}`}
        title="Visitar a página do produto na loja"
        target="_blank"
      >
        {slug} na loja
      </Styled.Link>
    )
  }

  const _renderProductStatus = (status: string): JSX.Element => {
    switch (status) {
      case 'AVAILABLE':
        return <Chip label="Disponível" />

      case 'USED':
        return <Chip label="Utilizado" variant="outlined" />

      default:
        return <></>
    }
  }

  const _renderFormattedDate = (date: string): string => {
    if (date === null) return ''

    const currentDate = format(parseISO(date), 'd/LL/yy KK:mm a', {
      locale: ptBR,
    })

    return currentDate
  }

  const _renderActionButton = (id: number, slug: string): JSX.Element => {
    return (
      <IconButton onClick={() => _handleItemDeletion(id, slug)}>
        <DeleteForever />
      </IconButton>
    )
  }

  const _renderCategorySelection = (): JSX.Element => {
    if (!categories.length) return <></>

    return (
      <FormControl>
        <InputLabel id="category-label">Filtro por categoria</InputLabel>
        <Select
          labelId="category-label"
          label="Filtro por categoria"
          value={currentCategory}
          onChange={(e): void => _handleProductFiltering(e.target.value)}
        >
          {categories.map((category, index) => {
            return <MenuItem key={index} value={category}>{category}</MenuItem>
          })}
        </Select>
      </FormControl>
    )
  }

  const columns: GridColumns = [
    {
      field: 'slug',
      headerName: 'Link do produto',
      disableColumnMenu: false,
      flex: 1,
      sortable: false,
      renderCell: (params) => _renderProductLink(params.row.slug),
    },
    {
      field: 'category',
      headerName: 'Categoria',
      disableColumnMenu: false,
      flex: 1,
      sortable: false,
    },
    {
      field: 'origin',
      headerName: 'Origem',
      disableColumnMenu: false,
      flex: 0,
      sortable: false,
    },
    {
      field: 'activationValue',
      headerName: 'Valor de ativação',
      disableColumnMenu: false,
      flex: 0,
      sortable: false,
    },
    {
      field: 'activationDate',
      headerName: 'Data de ativação',
      disableColumnMenu: false,
      flex: 0,
      sortable: false,
      renderCell: (params) => _renderFormattedDate(params.row.activationDate),
    },
    {
      field: 'expirationDate',
      headerName: 'Expiração',
      disableColumnMenu: false,
      flex: 0,
      sortable: false,
      renderCell: (params) => _renderFormattedDate(params.row.expirationDate),
    },
    {
      field: 'status',
      headerName: 'Status',
      disableColumnMenu: false,
      flex: 1,
      sortable: false,
      renderCell: (params) => _renderProductStatus(params.row.status),
    },
    {
      field: 'updatedAt',
      headerName: 'Atualização',
      disableColumnMenu: false,
      flex: 1,
      sortable: false,
      renderCell: (params) => _renderFormattedDate(params.row.updatedAt),
    },
    {
      field: 'id',
      headerName: 'Ações',
      disableColumnMenu: true,
      flex: 0,
      sortable: false,
      renderCell: (params) =>
        _renderActionButton(params.row.id, params.row.slug),
    },
  ]

  const _renderDataGrid = (): JSX.Element => {
    if (!inventoryItems)
      return <p>Busque um inventário usando o steamId64 do player acima</p>

    if (inventoryItems.length === 0)
      return <p>Nenhum item encontrado para o usuário {steamId64}</p>

    return (
      <DataGrid
        paginationMode="client"
        pageSize={10}
        rowCount={rowCount}
        rows={
          filteredItems && filteredItems.length ? filteredItems : inventoryItems
        }
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [{ field: 'updatedAt', sort: 'desc' }],
          },
        }}
        loading={loading}
        getRowId={(row) => row.id}
        rowsPerPageOptions={[10]}
        autoHeight
        rowHeight={60}
        disableSelectionOnClick
        sx={{
          ['&.MuiDataGrid-root .MuiDataGrid-cell:focus']: {
            outline: 'none',
          },
          ['&.MuiDataGrid-root .MuiDataGrid-cell']: {
            whiteSpace: 'break-spaces',
          },
        }}
      />
    )
  }

  useEffect(() => {
    steamId64 && _handleSearch()
  }, [])

  return (
    <Styled.Container>
      <HeaderTitle title="Inventário do player por steamId">
        <Person fontSize="large" style={{ fill: pink[500] }} />
      </HeaderTitle>
      <Styled.SearchForm>
        <TextField
          label="Steam ID 64 do jogador"
          variant="outlined"
          value={steamId64}
          onChange={(e): void => _handleInputChange(e.target.value)}
          disabled={loading}
          placeholder="76561198069569054"
        />
        <LoadingButton
          variant="contained"
          onClick={(): void => _handleSearch()}
          disabled={loading}
          loading={loading}
        >
          <PersonSearch /> {currentCategory !== '' ? 'Ver todos os itens' : 'Buscar'}
        </LoadingButton>
        {_renderCategorySelection()}
      </Styled.SearchForm>
      {_renderDataGrid()}
    </Styled.Container>
  )
}

export { PlayerInventory }
