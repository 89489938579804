import { createTheme, ThemeProvider } from '@mui/material'
import { pink } from '@mui/material/colors'

// COMPONENTS
import Router from './routes/Router'

// STYLES
import * as Styled from './styles'

export default function App(props) {
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: pink[500],
        dark: pink[800],
      },
    },
  })

  return (
    <ThemeProvider theme={darkTheme}>
      <Styled.Container>
        <Router />
      </Styled.Container>
    </ThemeProvider>
  )
}
