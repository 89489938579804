import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// COMPONENTS
import { HeaderTitle } from '@components/shared'
import { Autocomplete, TextField, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'

// SERVICES
import { getAllProducts } from '@services/marketplace/MarketplaceService'
import { addInventoryItemtoPlayer, batchAddInventoryItemtoPlayer } from '@services/inventory/InventoryService'

// ASSETS
import { AddBox } from '@mui/icons-material'

// TYPES
import {
  MarketplaceItem,
  MarketplaceItemSearchItem,
  MarketplaceRequestProps,
  MarketplaceSelectItem,
} from '@shared/types/MarketplaceTypes'
import { BatchInventoryDeliverRequestProps } from '@shared/types/InventoryTypes'

// STYLES
import * as Styled from './BatchDeliverInventoryItem.styles'
import { ConsoleLoader } from '@components/shared/Loader'
import { pink } from '@mui/material/colors'

const BatchDeliverInventoryItem = (): JSX.Element => {
  const navigate = useNavigate()

  const [marketplaceItems, setMarketplaceItems] = useState<
    MarketplaceSelectItem[]
  >([])

  const [requestData, setRequestData] = useState<BatchInventoryDeliverRequestProps>({
    activationValue: '',
    players: [],
    quantity: 1,
    slug: ''
  })

  const [selectedProduct, setSelectedProduct] = useState<MarketplaceSelectItem>()

  const [loading, setLoading] = useState<boolean>(false)

  const [saving, setSaving] = useState<boolean>(false)

  const [lengthError, setLengthError] = useState<boolean>(false)

  const shouldDisableActivationValue = (): boolean => {
    if (!selectedProduct) return true
    if (selectedProduct.category === 'double-rating-points' || selectedProduct.category === 'freeze-rating-points') {
      return false
    }

    return true
  }

  const updateProductState = (
    productKey: string,
    value: any,
    callback?: () => void
  ): void => {
    setRequestData((prev) => {
      return { ...prev, [productKey]: value }
    })
    callback && callback()
  }

  const _fetchMarketplaceItems = async (): Promise<void> => {
    setLoading(true)
    const marketplaceData: MarketplaceRequestProps = await getAllProducts()
    const coinsProducts = marketplaceData.results.map((product, p) => {
      return { label: `${p} - ${product.name}`, slug: product.slug, category: product.category.name }
    })
    setMarketplaceItems(coinsProducts)
    setLoading(false)
  }

  useEffect(() => {
    if (marketplaceItems.length) return
    _fetchMarketplaceItems()
  })

  const _formatTextareaValue = (): string => {
    if (requestData.players.length) {
      const ids = requestData.players.map((p) => `${p.id}, ${p.accountId}, ${p.credential}`)
      return ids.join('\n')
    }

    return ''
  }

  const _formatAndUpdatePlayerData = (data: string) => {
    setLengthError(false)
    const lines = data.split(/\n/g)
    const treated = lines.filter((p) => p !== '')
    const formatted = treated.map((s) => {
      const playerInfo = s.split(', ')
      return {
        id: playerInfo[0],
        accountId: playerInfo[1],
        credential: playerInfo[2]
      }
    })

    if (formatted.length > 50) {
      setLengthError(true)
      return
    }

    updateProductState('players', formatted)
  }

  const _handleSubmit = async (): Promise<void> => {
    if (lengthError) return

    setSaving(true)
    try {
      await batchAddInventoryItemtoPlayer(
        requestData.slug,
        requestData.activationValue,
        requestData.quantity,
        requestData.players,
      )
      Alert.success('A entrega foi realizada com sucesso.')
      navigate('/apps/inventory')
    } catch {
      Alert.error('Houve um problema ao realizar a entrega.')
    } finally {
      setSaving(false)
    }
  }

  const _renderFormContent = (): JSX.Element => {
    if (loading) {
      return <ConsoleLoader size={80} />
    }

    return (
      <Styled.FormContainer>
        <Styled.FormRow>
          <Autocomplete
            options={marketplaceItems}
            disabled={saving}
            disablePortal
            value={selectedProduct}
            onChange={(event, newValue: MarketplaceSelectItem) => {
              setSelectedProduct(newValue)
              updateProductState('slug', newValue.slug)
            }}
            id="marketplace-items"
            sx={{ width: 300 }}
            renderInput={(params) => {
              return <TextField {...params} label="Slug do produto" />
            }}
          />
        </Styled.FormRow>
        <Styled.FormRow>
          <TextField
            label="Quantidade por player"
            disabled={saving}
            type="number"
            value={requestData.quantity}
            onChange={(e) =>
              updateProductState('quantity', Number(e.target.value))
            }
          />
          <TextField
            label="Valor de ativação"
            disabled={shouldDisableActivationValue() || saving}
            type="number"
            value={requestData.activationValue}
            onChange={(e) =>
              updateProductState('activationValue', e.target.value)
            }
          />
        </Styled.FormRow>
        <Styled.FormNotes>
          Para entregar para vários players, <a href='https://console.gamersclub.com.br/apps/accounts/playersinfo/' rel='noopener' target="_blank" style={{ color: pink[500] }}>colete as informações dos players neste link</a>.<br />
          <strong style={{ color: pink[500] }}>Um player por linha com gcid, account id e steam id 64 separados por vírgula EXATAMENTE como no exemplo abaixo:</strong>
        </Styled.FormNotes>
        <Styled.FormRow>
          <Styled.FormNotes>
            7818, c18d33d3-b997-452f-9454-df579c8d41f4, 76561197980477545<br />
            126340, ddc2a2fd-849b-4c65-9abf-183c232b0874, 76561198069569054
          </Styled.FormNotes>
        </Styled.FormRow>
        <Styled.FormRow>
          <TextField
            label="Lista de jogadores"
            variant="outlined"
            fullWidth
            multiline={true}
            rows={5}
            onChange={(e) => _formatAndUpdatePlayerData(e.target.value)}
            value={_formatTextareaValue()}
          />
        </Styled.FormRow>
        <Styled.FormNotes highlighted={lengthError}>
          Limitado a 50 envios por tentativa.
        </Styled.FormNotes>
        <LoadingButton
          variant="contained"
          loading={saving}
          disabled={saving}
          onClick={() => _handleSubmit()}
        >
          Entregar produtos
        </LoadingButton>
      </Styled.FormContainer>
    )
  }

  return (
    <Styled.Wrapper>
      <Styled.Container>
        <HeaderTitle title="Entregar produto para vários players">
          <AddBox fontSize="large" style={{ fill: pink[500] }} />
        </HeaderTitle>
        {_renderFormContent()}
      </Styled.Container>
    </Styled.Wrapper>
  )
}

export { BatchDeliverInventoryItem }
