// API
import { inventoryApi } from '@shared/api/inventory/inventoryApi'

// TYPES
import {
  RemoveProductRequestProps,
  InventoryRequestProps,
  AddProductRequestProps,
  PlayerIdentifiers,
  BatchAddProductRequestProps,
} from '@shared/types/InventoryTypes'

const getInventoryItemsBySteamId64 = (
  steamId64: string,
  data?
): Promise<InventoryRequestProps> => {
  const queryString = data ? `?${new URLSearchParams(data).toString()}` : ''

  return inventoryApi.get(
    `/v1/backoffice/accounts/${steamId64}/items${queryString}`
  )
}

const addInventoryItemtoPlayer = (
  steamId64: string,
  productData: AddProductRequestProps
): Promise<AddProductRequestProps> => {
  return inventoryApi.post(
    `/v1/backoffice/accounts/${steamId64}/item/add`,
    productData
  )
}

const batchAddInventoryItemtoPlayer = (
  slug: string,
  activationValue: string,
  quantity: number,
  players: PlayerIdentifiers[]
): Promise<BatchAddProductRequestProps> => {
  return inventoryApi.post(`/v1/backoffice/items/deliver/batch`, {
    slug,
    activationValue,
    systemId: 1,
    quantity,
    players,
    origin: 'BACKOFFICE',
  })
}

const deleteInventoryItemFromPlayer = (
  itemId: number
): Promise<RemoveProductRequestProps> => {
  return inventoryApi.delete(`/v1/backoffice/items/${itemId}`)
}

const unbindMedalFromSlot = (
  steamId64: string,
  medalId: string
): Promise<RemoveProductRequestProps> => {
  return inventoryApi.post(`/v1/backoffice/accounts/${steamId64}/items/unbind/medal`, {
    medalId
  })
}

export {
  getInventoryItemsBySteamId64,
  addInventoryItemtoPlayer,
  deleteInventoryItemFromPlayer,
  batchAddInventoryItemtoPlayer,
  unbindMedalFromSlot
}
