import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// COMPONENTS
import { HeaderTitle } from '@components/shared'
import { LoadingButton } from '@mui/lab'
import { TextField } from '@mui/material'

// SERVICES
import { unbindMedalFromSlot } from '@services/inventory/InventoryService'

// ASSETS
import { RemoveOutlined } from '@mui/icons-material'

// STYLES
import * as Styled from './UnbindMedalFromSlot.styles'
import { pink } from '@mui/material/colors'

const UnbindMedalFromSlot = (): JSX.Element => { 
  const navigate = useNavigate()

  const [steamId64, setSteamId64] = useState<string>('')
  
  const [medalId, setMedalId] = useState<string>()

  const [saving, setSaving] = useState<boolean>(false)

  const _handleSubmit = async (): Promise<void> => {
    setSaving(true)

    try {
      await unbindMedalFromSlot(steamId64, medalId)
      Alert.success('A medalha foi desvinculada com sucesso.')
      navigate('/apps/inventory')
    } catch {
      Alert.error('Houve um problema ao desvincular a medalha do slot')
    } finally {
      setSaving(false)
    }
  }
  
  const _renderFormContent = (): JSX.Element => {
    return (
      <Styled.FormContainer>
        <Styled.FormRow>
        <TextField
          label="Steam ID 64 do jogador"
          variant="outlined"
          value={steamId64}
          onChange={(e): void => setSteamId64(e.target.value)}
          placeholder="76561198069569054"
        />
      </Styled.FormRow>
      <Styled.FormRow>
        <TextField
          label="ID da medalha"
          variant="outlined"
          value={medalId}
          onChange={(e): void => setMedalId(e.target.value)}
          placeholder="10"
        />
      </Styled.FormRow>
      <LoadingButton
          variant="contained"
          loading={saving}
          disabled={saving}
          onClick={() => _handleSubmit()}
        >
          Desvincular medalha
        </LoadingButton>
    </Styled.FormContainer>
    )
  }

  return (
  <Styled.Wrapper>
    <Styled.Container>
      <HeaderTitle title="Desvincular medalha de slot">
        <RemoveOutlined fontSize="large" style={{ fill: pink[500] }} />
      </HeaderTitle>
      {_renderFormContent()}
    </Styled.Container>
  </Styled.Wrapper>
  ) 
}

export { UnbindMedalFromSlot }