import React from 'react'
import classnames from 'classnames'

import { ConsoleLoaderProps } from './types'

import './Loader.scss'

const ConsoleLoader = (props: ConsoleLoaderProps): JSX.Element => {
  const { color, size, className, ...rest } = props

  const colorValue = color || `#fff`
  const sizePx = `${size}px`
  return (
    <div className={classnames('ConsoleLoader', className)} {...rest}>
      <svg width={sizePx} height={sizePx} viewBox="0 0 54 54">
        <path
          stroke={colorValue}
          className="ConsoleLoader__path"
          fill="transparent"
          strokeWidth="1.5"
          d="M41.5653846,
            15.0917602 L35.3035593,
            19.4458834 C33.2455032,
            16.5277589 29.847357,
            14.621399 26.0020594,
            14.621399 C19.721816,
            14.621399 14.6298835,
            19.7091778 14.6298835,
            25.9841387 C14.6298835,
            28.3773577 15.3693376,
            30.596506 16.6347317,
            32.4274464 L10.3725654,
            36.7798861 C8.24527066,
            33.7156366 7,
            29.9944976 7,
            25.9841387 C7,
            15.4994963 15.5088379,
            7 26.0020594,
            7 C32.4398802,
            7 38.1304022,
            10.197917 41.5653846,
            15.0917602 Z M43.5533892,
            18.7533207 C44.485839,
            20.991527 45,
            23.4474629 45,
            26.0238736 C45,
            36.5048443 36.4892203,
            45 25.9924214,
            45 C21.001273,
            45 16.4570728,
            43.0784607 13.0653846,
            39.9353473 L19.5819568,
            35.4074468 C21.4079617,
            36.6529139 23.6147255,
            37.3808105 25.9924214,
            37.3808105 C30.5819988,
            37.3808105 34.5383428,
            34.6667624 36.3350061,
            30.7577254 L26.2742375,
            30.7577254 L43.5533892,
            18.7533207 Z"
        />
        <circle
          fill={colorValue}
          className="ConsoleLoader__circle"
          cx="17.5"
          cy="25"
          r="0"
        >
          <animate
            attributeName="r"
            values="0; 4; 0; 0"
            dur="1.5s"
            repeatCount="indefinite"
            begin="1.5"
            keyTimes="0;0.2;0.7;1"
            keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8"
            calcMode="spline"
          />
        </circle>
        <circle
          fill={colorValue}
          className="ConsoleLoader__circle"
          cx="25.5"
          cy="25"
          r="0"
        >
          <animate
            attributeName="r"
            values="0; 4; 0; 0"
            dur="1.5s"
            repeatCount="indefinite"
            begin="1.7"
            keyTimes="0;0.2;0.7;1"
            keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8"
            calcMode="spline"
          />
        </circle>
        <circle
          fill={colorValue}
          className="ConsoleLoader__circle"
          cx="33.5"
          cy="25"
          r="0"
        >
          <animate
            attributeName="r"
            values="0; 4; 0; 0"
            dur="1.5s"
            repeatCount="indefinite"
            begin="1.9"
            keyTimes="0;0.2;0.7;1"
            keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8"
            calcMode="spline"
          />
        </circle>
      </svg>
    </div>
  )
}

export { ConsoleLoader }
