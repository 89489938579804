import { DeliverInventoryItem, PlayerInventory, UnbindMedalFromSlot } from '@components'
import { BatchDeliverInventoryItem } from '@components/BatchDeliverInventoryItem'

type Route = {
  component: JSX.Element
  exact: boolean
  path: string
}

const routes = (): Route[] => [
  // CATEGORIES
  {
    component: <PlayerInventory />,
    exact: true,
    path: '/apps/inventory/',
  },
  {
    component: <PlayerInventory />,
    exact: true,
    path: '/apps/inventory/:steamId',
  },
  {
    component: <DeliverInventoryItem />,
    exact: true,
    path: '/apps/inventory/add',
  },
  {
    component: <BatchDeliverInventoryItem />,
    exact: true,
    path: '/apps/inventory/batch/add',
  },
  {
    component: <UnbindMedalFromSlot />,
    exact: true,
    path: '/apps/inventory/unbind-medal',
  }
]

export default routes
