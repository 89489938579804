import styled from 'styled-components'

interface HighlightProps {
  highlighted: boolean
}

export const Wrapper = styled.div`
  width: 100%;
`

export const Container = styled.div`
  width: 100%;
  max-width: 540px;
`

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 20px;

  .MuiAutocomplete-root,
  .MuiTextField-root,
  .MuiButton-root,
  .MuiInputBase-root {
    width: 100%;
  }
`

export const FormRow = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  aling-items: center;
  justify-content: space-between;

  .MuiTextField-root:last-child:not(:only-child) {
    margin-left: 20px;
  }
`

export const FormNotes = styled.p<HighlightProps>`
  width: 100%;
  text-align: left;
  font-size: ${({ highlighted }) => (highlighted ? '24px' : '14px')};
  color: ${({ highlighted }) =>
    highlighted ? 'red' : 'rgba(255, 255, 255, 0.7)'};
  margin-bottom: 10px;
  font-weight: ${({ highlighted }) => (highlighted ? 'bold' : '400')};
  line-height: 1.4;
`

export const FormOr = styled.p`
  margin: 0 30px;
`
