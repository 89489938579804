// API
import { marketplaceApi } from '@shared/api/marketplace/marketplaceApi'

// TYPES
import { MarketplaceRequestProps } from '@shared/types/MarketplaceTypes'

const getAllProducts = (): Promise<MarketplaceRequestProps> => {
  return marketplaceApi.get(`/backoffice/products?limit=1999&page=1`)
}

export { getAllProducts }
