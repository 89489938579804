import { useState } from 'react'
import {
  Drawer,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Collapse,
} from '@mui/material'

import {
  Person,
  ManageSearch,
  ExpandLess,
  ExpandMore,
  ArrowBackRounded,
  Inventory,
  Add,
  Group,
  Remove,
} from '@mui/icons-material'
import { BACKOFFICE_URL } from '@config/environment'

// STYLES
import * as Styled from './SideBar.styles'

const SideBar = (): JSX.Element => {
  const drawerWidth = 220

  const menuWithSubItems = [
    {
      id: 1,
      name: 'Inventário do player',
      icon: <Person />,
      subItems: [
        {
          name: 'Consultar',
          link: '/apps/inventory',
          icon: <ManageSearch />,
        },
      ],
    },
    {
      id: 2,
      name: 'Entrega de itens',
      icon: <Add />,
      subItems: [
        {
          name: 'Adicionar para um',
          link: '/apps/inventory/add/',
          icon: <Person />,
        },
        {
          name: 'Adicionar para vários',
          link: '/apps/inventory/batch/add/',
          icon: <Group />,
        },
      ],
    }  
  ]

  const menuItems = [
    {
      id: 1,
      name: 'Desvincular medalha de slot',
      icon: <Remove />,
      link: '/apps/inventory/unbind-medal',
    },
  ]

  const [isMenuVisible, setIsMenuVisible] = useState(
    menuWithSubItems.reduce(
      (menuItem, { id }) => ({
        ...menuItem,
        [id]: false,
      }),
      {}
    )
  )

  const handleClick = (id: number): void => {
    setIsMenuVisible({
      ...isMenuVisible,
      [id]: !isMenuVisible[id],
    })
  }

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        ['& .MuiDrawer-paper']: {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: 'primary.main',
        },
      }}
    >
      <Toolbar sx={{ mt: 2 }} />
      {menuWithSubItems.map((item, itemsIndex) => (
        <List component="nav" key={itemsIndex} disablePadding>
          <ListItemButton onClick={(): void => handleClick(item.id)}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText
              primary={item.name}
              disableTypography
              sx={{ fontWeight: '300' }}
            />
            {isMenuVisible[item.id] ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={isMenuVisible[item.id]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {item.subItems.map((subItem, subItemIndex) => (
                <Styled.LinkMenu key={subItemIndex} to={subItem.link}>
                  <ListItem button key={subItem.name} sx={{ pl: 4 }}>
                    <ListItemIcon>{subItem.icon}</ListItemIcon>
                    <ListItemText
                      primary={subItem.name}
                      disableTypography
                      sx={{ fontWeight: '300' }}
                    />
                  </ListItem>
                </Styled.LinkMenu>
              ))}
            </List>
          </Collapse>
        </List>
      ))}

      <List component="nav" disablePadding>
        <List component="div" disablePadding>
          {menuItems.map((item) => (
            <Styled.LinkMenu key={item.id} to={item.link}>
              <ListItem button sx={{ pl: 2 }}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText
                  primary={item.name}
                  disableTypography
                  sx={{ fontWeight: '300' }}
                />
              </ListItem>
            </Styled.LinkMenu>
          ))}
        </List>
      </List>
      <Styled.LinkOldBackoffice
        href={`${BACKOFFICE_URL}/marketplace`}
        title={`Voltar para o backoffice da multigaming`}
        target="_blank"
      >
        <ArrowBackRounded />
        backoffice multigaming
      </Styled.LinkOldBackoffice>
      <Styled.Footer>
        &copy; {new Date().getFullYear()}{' '}
        <Styled.FooterLink href="http://gamersclub.com.br" target="_blank">
          GamersClub
        </Styled.FooterLink>{' '}
        made with ❤️ for a better backoffice
      </Styled.Footer>
    </Drawer>
  )
}

export { SideBar }
