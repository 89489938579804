import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// HELPERS
import * as Alert from '@shared/helpers/Alerts'

// COMPONENTS
import { HeaderTitle } from '@components/shared'
import { Autocomplete, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'

// SERVICES
import { getAllProducts } from '@services/marketplace/MarketplaceService'
import { addInventoryItemtoPlayer } from '@services/inventory/InventoryService'

// ASSETS
import { AddBox } from '@mui/icons-material'

// TYPES
import {
  MarketplaceItemSearchItem,
  MarketplaceRequestProps,
} from '@shared/types/MarketplaceTypes'
import { ProductStateProps } from '@shared/types/InventoryTypes'

// STYLES
import * as Styled from './DeliverInventoryItem.styles'
import { ConsoleLoader } from '@components/shared/Loader'
import { pink } from '@mui/material/colors'

const DeliverInventoryItem = (): JSX.Element => {
  const navigate = useNavigate()

  const [marketplaceItems, setMarketplaceItems] = useState<
    MarketplaceItemSearchItem[]
  >([])

  const [productData, setProductData] = useState<ProductStateProps>({
    product: {
      label: '',
      id: '',
    },
    quantity: 1,
  })

  const [steamId64, setSteamId64] = useState<string>('')

  const [loading, setLoading] = useState<boolean>(false)

  const [saving, setSaving] = useState<boolean>(false)

  const updateProductState = (
    productKey: string,
    value: any,
    callback?: () => void
  ): void => {
    setProductData((prev) => {
      return { ...prev, [productKey]: value }
    })
    callback && callback()
  }

  const _fetchMarketplaceItems = async (): Promise<void> => {
    setLoading(true)
    const marketplaceData: MarketplaceRequestProps = await getAllProducts()
    const coinsProducts = marketplaceData.results.map((product, p) => {
      return { label: `${p} - ${product.name}`, id: product.id }
    })
    setMarketplaceItems(coinsProducts)
    setLoading(false)
  }

  useEffect(() => {
    if (marketplaceItems.length) return
    _fetchMarketplaceItems()
  })

  const _handleSubmit = async (): Promise<void> => {
    setSaving(true)
    try {
      await addInventoryItemtoPlayer(steamId64, {
        productId: productData.product.id,
        quantity: productData.quantity,
      })
      Alert.success('A entrega foi realizada com sucesso.')
      navigate('/apps/inventory')
    } catch {
      Alert.error('Houve um problema ao realizar a entrega.')
    } finally {
      setSaving(false)
    }
  }

  const _renderFormContent = (): JSX.Element => {
    if (loading) {
      return <ConsoleLoader size={80} />
    }

    return (
      <Styled.FormContainer>
        <Styled.FormRow>
          <Autocomplete
            options={marketplaceItems}
            disabled={saving}
            disablePortal
            value={productData.product}
            onChange={(event, newValue) => {
              updateProductState('product', newValue)
            }}
            id="marketplace-items"
            sx={{ width: 300 }}
            renderInput={(params) => {
              return <TextField {...params} label="Produto" />
            }}
          />
        </Styled.FormRow>
        <Styled.FormRow>
          <TextField
            label="Steam ID 64*"
            disabled={saving}
            required
            onChange={(e) => setSteamId64(e.target.value)}
          />
          <TextField
            label="Quantidade"
            disabled={saving}
            type="number"
            value={productData.quantity}
            onChange={(e) =>
              updateProductState('quantity', Number(e.target.value))
            }
          />
        </Styled.FormRow>
        <LoadingButton
          variant="contained"
          loading={saving}
          disabled={saving}
          onClick={() => _handleSubmit()}
        >
          Enviar produto
        </LoadingButton>
      </Styled.FormContainer>
    )
  }

  return (
    <Styled.Wrapper>
      <Styled.Container>
        <HeaderTitle title="Entregar produto para um player">
          <AddBox fontSize="large" style={{ fill: pink[500] }} />
        </HeaderTitle>
        {_renderFormContent()}
      </Styled.Container>
    </Styled.Wrapper>
  )
}

export { DeliverInventoryItem }
