import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
`

export const Container = styled.div`
  width: 100%;
  max-width: 540px;
`

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 20px;

  .MuiAutocomplete-root,
  .MuiTextField-root,
  .MuiButton-root,
  .MuiInputBase-root {
    width: 100%;
  }
`

export const FormRow = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  aling-items: center;
  justify-content: space-between;

  .MuiTextField-root:last-child:not(:only-child) {
    margin-left: 20px;
  }
`
